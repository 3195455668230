<template>
  <div class="detail">
    <el-breadcrumb style="margin: 15px 0" separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' + $route.query.backPage }">{{ $route.query.backTitle }}</el-breadcrumb-item>
      <el-breadcrumb-item>详情</el-breadcrumb-item>
    </el-breadcrumb>
    <div v-if="newsInfo">
      <div class="title">
        <div class="detail-title">{{ newsInfo.title }}</div>
        <div class="detail-time">{{ newsInfo.createTime }}</div>
      </div>
      <div class="content" v-html="newsInfo.content">
      </div>
    </div>
  </div>
</template>

<script>
import {getArt} from "@/api/api";
import {serverUrl} from "@/utils/config";

  export default {
    name: 'Detail',
    data() {
      return {
        serverUrl: serverUrl,
        activeName: "first",
        tabs: ["联系我们"],
        tabIndex: 0,
        newsInfo: null
      };
    },
    activated() {
      this.getData();
    },
    methods: {
      getData() {
        getArt({
          articleId: this.$route.query.id
        }).then((resp) => {
          let {code, data} = resp;
          if (code === 0) {
            this.newsInfo = data;
          }
        })
      },
    }
  }
</script>

<style lang="scss" scoped>
  @import "./new_detail.scss";
/*.detail{*/
/*  padding:10px 10%;*/
/*  .title{*/
/*    text-align: center;*/
/*  }*/
/*  .detail-title{*/
/*    font-size: 24px;*/
/*    line-height: 35px;*/
/*    font-weight: bold;*/
/*  }*/
/*  .border-bottom{*/
/*    border-bottom: 2px solid #CACACA;*/
/*  }*/
/*  .detail-time{*/
/*    font-size: 8px;*/
/*    line-height: 35px;*/
/*  }*/
/*.p{*/
/*  color: #999999;*/
/*  font-size: 8px;*/
/*  text-indent:24px;*/
/*  line-height: 20px;*/
/*}*/
/*  .img-center{*/
/*    margin: 15px auto;*/
/*    text-align: center;*/
/*    img{*/
/*      width: 300px;*/
/*    }*/
/*  }*/
/*}*/
</style>
